<template>

    <div v-if="!paymentModal" class="price__heading">
        <div class="price__heading-box">
            <BackButton class="back-button"/>
            <h2 class="mb0 h1">{{ $t('general.buy_credits') }}</h2>
        </div>

        <div class="u-text-center">
            <div class="o-flex o-flex--center o-flex--justify-center">
                <img :src="`../../../img/black-friday/${auth.user.lang.toUpperCase()}.svg`" class="mr" width="100" alt="badge">
                <p v-if="timeRemaining" class="u-text-center clr-white mb0">{{$t('promotions.hurry_up')}} <br>
                    <span class="o-flex o-flex--center">
                        <svg class="mr- clock" xmlns="http://www.w3.org/2000/svg" width="17" height="20"
                                viewBox="0 0 17.143 20">
                            <path fill="#fff"
                                    d="M11.429 0H5.714v1.9h5.714Zm-3.81 12.381h1.9V6.667h-1.9Zm7.648-6.3 1.352-1.352a10.522 10.522 0 0 0-1.343-1.339l-1.352 1.353a8.571 8.571 0 1 0 1.343 1.343Zm-6.7 12.01a6.667 6.667 0 1 1 6.667-6.667A6.662 6.662 0 0 1 8.571 18.1Z"
                                    data-name="Icon material-timer"/>
                        </svg>
                        {{ timeRemaining }}
                    </span></p>
            </div>
        </div>

    </div>

    <div v-else>
        <div v-if="promotion.template !== 'black_friday_20'" class="amount-box">
            <img src="../../assets/img/dollar-icon.svg" alt="Dollar icon"> {{credits}}
        </div>
        <h2 v-if="promotion.template !== 'black_friday_20'" class="h4">{{$t('credits.not_enough_credits')}}? {{$t('credits.recharge')}}:</h2>
        <div v-else class="o-flex o-flex--center o-flex--justify-center">
            <img :src="`../../../img/black-friday/${auth.user.lang.toUpperCase()}.svg`" class="mr-" width="50" alt="badge">
            <p v-if="timeRemaining" class="u-text-center mb0">{{$t('promotions.hurry_up')}} <br>
                <span class="o-flex o-flex--center">
                    <svg class="mr- clock" xmlns="http://www.w3.org/2000/svg" width="17" height="20"
                         viewBox="0 0 17.143 20">
                        <path fill="#000"
                              d="M11.429 0H5.714v1.9h5.714Zm-3.81 12.381h1.9V6.667h-1.9Zm7.648-6.3 1.352-1.352a10.522 10.522 0 0 0-1.343-1.339l-1.352 1.353a8.571 8.571 0 1 0 1.343 1.343Zm-6.7 12.01a6.667 6.667 0 1 1 6.667-6.667A6.662 6.662 0 0 1 8.571 18.1Z"
                              data-name="Icon material-timer"/>
                    </svg>
                    {{ timeRemaining }}
                </span>
            </p>
        </div>

    </div>
</template>
<script>
import BackButton from "@/components/generic/BackButton.vue";
import ThemeMixin from "@/mixins/ThemeMixin";
import { mapState } from "vuex";
import moment from "moment";

import { daysHoursMinutesSecondsSince } from "@/utilities/general";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
export default {
    name: "BlackFriday20Header",
    components: { BackButton },
    mixins: [ThemeMixin, AuthenticatedMixin],
    props: {
        promotion: {
            type: Object
        },
        paymentModal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            timeRemainingInterval: null,
            timeRemaining: '',
            daysLeft: 0,
            days: 0,
            validUntil: null
        }
    },
    computed: {
        ...mapState({
            credits: state => state.credits.amount
        })
    },
    methods: {
        async setDynamicValues(end)
        {
            this.timeRemaining = daysHoursMinutesSecondsSince(moment.utc(), end)

            this.daysLeft = parseInt(this.timeRemaining.split(':')[0])
        }
    },
    mounted() {

        if(this.promotion.valid_until) {

            this.validUntil = moment.utc(this.promotion.valid_until)

            this.setDynamicValues(this.validUntil)

            this.timeRemainingInterval = setInterval(() => {
                this.setDynamicValues(this.validUntil)
            }, 1000)

        }

    },
    beforeUnmount() {
        clearInterval(this.timeRemainingInterval)
    },
    created() {
        this.conversation_price = process.env.VUE_APP_UNLOCK_CONVERSATION
    }
}

</script>
<style scoped lang="scss">
    .price__heading {
        text-align: left;

        h2 {
            margin-bottom: 10px;
            font-weight: 700;
        }

        p {
            font-size: 18px;
            line-height: 24px;
        }

        @media(max-width: 991px) {
            padding: 0 20px;

            p {
                margin-bottom: 0;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.06px;
            }
        }
    }

    .price__heading-box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .back-button {
            margin-right: 20px;
            display: none;
        }

        @media(max-width: 991px) {
            margin-bottom: 25px;

            .back-button {
                display: flex;
            }
        }
    }

    .amount-box {
        border-radius: 15px;
        background-color: #fff;
        padding-left: 40px;
        position: relative;
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        height: 30px;
        width: auto;
        padding-right: 20px;
        margin-bottom: 10px;
        font-size: 18px;
        font-family: "Open Sans", sans-serif!important;

        img {
            position: absolute;
            top: 0;
            left: 0;
        }

        @media(max-width: 350px) {
            min-width: 105px;
        }
    }
</style>
