import {mapUser} from "@/service/gtm";
import i18n from "@/i18n";

export const startBeacon = user => {

    if( ! process.env.VUE_APP_HELP_SCOUT_KEY) {
        return console.log('Help Scout key not set')
    }

    let payload = mapUser(user)
    payload.created_at = payload.created_at ? payload.created_at.split(' ')[0] : null
    payload.email_verified_at = payload.email_verified_at ? payload.email_verified_at.split(' ')[0] : null
    payload.profile_verified_at = payload.profile_verified_at ? payload.profile_verified_at.split(' ')[0] : null
    payload.last_activity = payload.last_activity ? payload.last_activity.split(' ')[0] : null

    window.Beacon('init', process.env.VUE_APP_HELP_SCOUT_KEY)
    window.Beacon('identify', payload)
    window.Beacon('config', {
        color: '#B89450',
        zIndex: 10000,
        display: {
            style: 'manual',
            iconImage: 'message',
            horizontalOffset: 20,
            verticalOffset: 20,
            horizontalMobileOffset: 10,
            verticalMobileOffset: 80,

        },
        labels: i18n?.global?.messages[i18n.global.locale]?.general?.helpscout?.beacon
    })

}

export const stopBeacon = () => window.Beacon('destroy')