<template>
    <transition name="fade" mode="out-in">
        <teleport to="#modals">
            <PayPalConfirmModal
                v-if="payPalOrderModalShow && selectedPrice"
                :selected-package="selectedPrice"
                @close="payPalOrderModalShow = false"
                @confirmed="payPalConfirmed"
            />
        </teleport>
    </transition>
    <Component
        v-if="promo?.header"
        :is="promo?.header"
        :promotion="promo?.promotion"
        :paymentModal="paymentModal"
    />
    <PaymentTypes v-if="promo.promotion" :types="promo?.promotion?.payment_types ?? []" @select="changeType" />
    <br>
    <div>
    <PrivacyAndTerms @update="updateTerms" />
    </div>
    <Component
        :is="promo?.component"
        v-if="promo?.component && (toggle ? ! confirm : true) && promo?.promotion"
        :packages="availablePackages"
        :promotion="promo?.promotion"
        :valid-until="expiringDate"
        :working="working"
        @selected="packageSelectHandler"
        :paymentModal="paymentModal"
        :confirmed="terms"
    />
    <slot name="footer"></slot>
    <ConfirmPaymentWithCard
        v-if="selectedPrice && confirm"
        :selected-price="selectedPrice"
        @success="handlePaymentSuccess"
        @error="handlePaymentError"
        @reject="startTransaction(selectedPrice)"
        @close="selectedPrice = null; confirm = false;"
    />
</template>

<script>
import ThemeMixin from "@/mixins/ThemeMixin";
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import PaymentContent from "@/components/payment/PaymentContent.vue";
import ConfirmPaymentWithCard from "@/components/payment/ConfirmPaymentWithCard.vue";
import DefaultPromotion from "@/components/payment/DefaultPromotion.vue";
import Modal from "@/components/generic/Modal.vue";
import LimitedPromotionDays from "@/components/payment/LimitedPromotionDays.vue";
import LimitedPromotionPackage from "@/components/payment/LimitedPromotionPackage.vue";
import BackButton from "@/components/generic/BackButton.vue";
import DefaultPromotionHeader from "@/components/payment/DefaultPromotionHeader.vue";
import LimitedPromotionDaysHeader from "@/components/payment/LimitedPromotionDaysHeader.vue";
import LimitedPromotionPackageHeader from "@/components/payment/LimitedPromotionPackageHeader.vue";
import Discount from "@/components/payment/Discount.vue";
import {mapActions, mapState} from "vuex";
import {setAvailablePromotions} from "@/service/promotions";
import moment from "moment/moment";
import toastr from "toastr";
import {pushDataLayer} from "@/service/gtm";
import { GTM_EVENT_SELECT_PACKAGE } from "@/service/gtm/types";
import {create} from "@/api/payment";
import PayPalConfirmModal from "@/components/payment/PayPalConfirmModal.vue";
import { PAYPAL, CREDIT_CARD} from "@/types/payment";
import PaymentTypes from "@/components/payment/PaymentTypes.vue";
import BlackFriday50Header from "@/components/payment/BlackFriday50Header.vue";
import BlackFriday50 from "@/components/payment/BlackFriday50.vue";
import BlackFriday20Header from "@/components/payment/BlackFriday20Header.vue";
import BlackFriday20 from "@/components/payment/BlackFriday20.vue";
import SFlowOneHeader from "@/components/payment/SFlowOneHeader.vue";
import SFlowOne from "@/components/payment/SFlowOne.vue";
import PrivacyAndTerms from "@/components/payment/PrivacyAndTerms.vue";

export default {
    name: "Payment",
    mixins: [ThemeMixin, AuthenticatedMixin],
    components: {
        PrivacyAndTerms,
        PaymentTypes,
        PayPalConfirmModal,
        PaymentContent,
        ConfirmPaymentWithCard, DefaultPromotion, Modal, LimitedPromotionDays, LimitedPromotionPackage,
        BackButton, DefaultPromotionHeader, LimitedPromotionDaysHeader, LimitedPromotionPackageHeader, Discount,
        BlackFriday50Header, BlackFriday50, BlackFriday20Header, BlackFriday20, SFlowOneHeader, SFlowOne
    },
    emits: ['requestRegister', 'success', 'error'],
    props: {
        toggle: {
            type: Boolean,
            default: false
        },
        paymentModal: {
            type: Boolean,
            default: false
        },
        promo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            terms: true,
            working: false,
            confirm: false,
            selectedPrice: null,
            type: null,
            payPalOrderModalShow: false,
            changePaymentType: false
        }
    },
    computed: {
        ...mapState({
            card: state => state.credits.card
        }),

        expiringDate() {

            if( ! this.promo?.promotion || this.promo.promotion.default)
                return null

            if(this.promo.promotion.valid_until) {
                return moment.utc(this.promo.promotion.valid_until)
            }

            return moment.utc(this.auth.user.created_at, 'YYYY-MM-DD HH:mm:ss').add('3', 'day')
        },

        availablePackages() {
            return this.promo?.promotion?.packages?.filter(e => ! e.suspended).filter(e => e.payment_types[this.type]?.length && (e.payment_types[this.type].includes('*') || e.payment_types[this.type].includes(this.auth.user.currency)))
        }
    },
    watch: {
        promo: {
            handler(value) {
                this.type = value.promotion?.payment_types.includes(CREDIT_CARD) ? CREDIT_CARD : (value.promotion?.payment_types[0] ?? null)
            },
            immediate: true
        }
    },

    methods: {
        ...mapActions({
            addPreAction: "profile/add"
        }),
        updateTerms(value) {
            this.terms = value
        },
        packageSelectHandler(p) {

            if( ! this.terms)
                return

            this.selectedPrice = p

            if(this.card && this.type === CREDIT_CARD) {

                return this.confirm = true

            }

            if(this.type === PAYPAL) {
                return this.payPalOrderModalShow = true
            }

            this.startTransaction(p)
        },

        payPalConfirmed() {
            this.startTransaction(this.selectedPrice)
        },

        changeType(type) {
            this.type = type
        },

        async handlePaymentError(e) {
            console.log('Error creating transaction', e)
            toastr.error(this.$i18n.t('credits.error_processing'))
            this.selectedPrice = null
            this.confirm = false
            await setAvailablePromotions()
            this.$emit('error')
            this.working = false
        },

        async handlePaymentSuccess(payload) {
            this.working = false
            this.$emit('success', payload)
        },

        async startTransaction(p) {

            pushDataLayer(GTM_EVENT_SELECT_PACKAGE.replace('{id}', p.id), this.$store.state.authenticate.auth.user)

            if(this.working)
                return

            this.working = true

            try {

                const { data } = await create({type: this.type, price_id: p.price_id, one_click: false})

                this.working = false

                return this.$emit('success', data)


            } catch (e) {
                await this.handlePaymentError(e)
            }

        }
    },
    created() {
        this.conversation_price = process.env.VUE_APP_UNLOCK_CONVERSATION
        this.gallery_price = process.env.VUE_APP_UNLOCK_GALLERY
    }
}
</script>

<style scoped lang="scss">

    .modal--paypal {
        text-align: center;

        p {
            line-height: 1.3;
            font-size: 14px;
        }
    }
</style>