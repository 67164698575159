import { getCountryByAlpha2 } from "@/utilities/countries";

export const push = (type, payload) => {

    const data = {
        event: type,
        ...payload,
        timestamp: Math.floor(Date.now() / 1000)
    }

    if( process.env.VUE_APP_ENVIRONMENT === 'local') {
        return console.log('dataLayer', data)
    }

    return window.dataLayer.push(data)

}

export const GTMSale = (saleType, {id, age, gender, utm_source, utm_medium, utm_campaign, utm_content, utm_term, username, currencyCode, transactionId, transactionTotal, transactionProducts}) => {
    push(saleType, {id, age, gender, utm_source, utm_medium, utm_campaign, utm_content, utm_term, username, currencyCode, transactionId, transactionTotal, transactionProducts})
}



export const mapUser = user => {
    const [createdAtDate, createdAtMinutes] = user.created_at ? user.created_at.split(":") : []
    const [emailAtDate, emailAtMinutes] = user.email_verified_at ? user.email_verified_at.split(":") : []
    const [lastActivityDate, lastActivityMinutes] = user.last_activity ? user.last_activity.split(":") : []
    const isInstalled = window.matchMedia('(display-mode: standalone)')?.matches ?? window.navigator?.standalone === true
    const pushEnabled = window?.Notification?.permission === "granted"
    const pushSupported = "Notification" in window

    return {
        id: user.id,
        user_id: user.id,
        username: user.username,
        email: user.email,
        age: user.age,
        body_type: user.body_type,
        city: user.city,
        country: getCountryByAlpha2(user.country)?.name,
        created_at: user.created_at ? [createdAtDate, createdAtMinutes].join(":") : null,
        email_verified_at: user.email_verified_at ? [emailAtDate, emailAtMinutes].join(":") : null,
        available: user.credits?.available ?? 0,
        total_spent: user.credits?.total_spent ?? 0,
        description: user.description,
        greeting: user.greeting,
        needs: user.needs,
        drinking: user.drinking,
        education: user.education,
        ethnicity: user.ethnicity,
        eye_color: user.eye_color,
        hair_color: user.hair_color,
        smoking: user.smoking,
        tattoo: user.tattoo,
        height: user.height,
        income: user.income,
        net_worth: user.net_worth,
        piercing: user.piercing,
        flow: user.flow,
        gender: user.gender,
        lang: user.lang,
        last_activity: user.last_activity ? [lastActivityDate, lastActivityMinutes].join(":") : null,
        profile_verified_at: user.profile_verified_at,
        local_currency: user.local_currency?.name,
        offers_meeting: user.offers?.find(i => i.type === "meeting")?.amount ?? 0,
        offers_weekend: user.offers?.find(i => i.type === "weekend")?.amount ?? 0,
        offers_month: user.offers?.find(i => i.type === "month")?.amount ?? 0,
        profile_photo: user.profile_photo?.file ?? null,
        interested_in_rinsing: !! user.interested_in?.find(e => e.interested_in === 0),
        interested_in_selling_pic: !! user.interested_in?.find(e => e.interested_in === 1),
        interested_in_meetings: !! user.interested_in?.find(e => e.interested_in === 2),
        interested_in_travel: !! user.interested_in?.find(e => e.interested_in === 3),
        interested_in_weekly_dates: !! user.interested_in?.find(e => e.interested_in === 4),
        interested_in_discreet_meetings: !! user.interested_in?.find(e => e.interested_in === 5),
        interested_in_sensual_moments: !! user.interested_in?.find(e => e.interested_in === 6),
        interested_in_luxury: !! user.interested_in?.find(e => e.interested_in === 7),
        interested_in_romance: !! user.interested_in?.find(e => e.interested_in === 8),
        interested_in_diner_dates: !! user.interested_in?.find(e => e.interested_in === 9),
        interested_in_shows: !! user.interested_in?.find(e => e.interested_in === 10),
        use_pwa: isInstalled,
        push_enabled: pushEnabled,
        push_supported: pushSupported
    }
}

export const pushDataLayer = (event, user) => push(event, mapUser(user));